<template>
  <div>
    <!-- picC0875CB736A90FF0964ED3F0A1F70050.jpg -->
    <img class="img-large rounded" src="">
    <p>Scientists specializing in deep space exploration have contacted the LEGO Universe News Network with claims of an incredible find! The explorers say their team has discovered a small pod built from LEGO bricks -- of extraterrestrial origins!
    </p>
    <p>
      The explorers are convinced that the mysterious pod was piloted by a LEGO minifigure they found inside, and they believe a message could be encoded in a strange snippet of data discovered near the minifigure.
    </p>
    <p>
      One of the scientists has asked us to deliver this important message:
    </p>
    <p>
      <i>"We believe more of these alien LEGO pods are heading for Earth! It’s impossible to tell where the pods might touchdown, but my team is desperate for evidence of their whereabouts! Hopefully LEGO fans will be able to help us find the other pods and reveal the mystery.”</i>
    </p>
    <p>
      To stay on top of the latest developments visit this site: <a href="http://www.bradfordrant.org/">www.Bradfordrant.org</a>
    </p>
  </div>
</template>
